import { render, staticRenderFns } from "./ModelVideo.vue?vue&type=template&id=4fe7fe4f&scoped=true&lang=pug&"
import script from "./ModelVideo.vue?vue&type=script&lang=ts&"
export * from "./ModelVideo.vue?vue&type=script&lang=ts&"
import style0 from "./ModelVideo.vue?vue&type=style&index=0&id=4fe7fe4f&scoped=true&lang=scss&"
import style1 from "./ModelVideo.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fe7fe4f",
  null
  
)

export default component.exports