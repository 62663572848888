



























import {Component, Prop, Vue} from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import {AffiliateContentResponse} from '@/types/AffiliateContentResponse';
import Vuelidate from 'vuelidate';
import {maxLength} from 'vuelidate/lib/validators';
import {ModelDataInterface} from '@/types/userDataInterface';
import {vxm} from '@/store';

Vue.use(Vuelidate);

@Component({
  components: {
    Modal,
  },
  validations: {
    tweetText: {
      maxLength: maxLength(280),
    },
  },
})
export default class TweetClipModal extends Vue {
  tweetText = '';
  isRequestSend = false;

  @Prop() readonly video!: AffiliateContentResponse;

  mounted() {
    this.tweetText = this.video.twitterText
      ? this.video.twitterText
      : this.user.defaultTweetText
      ? this.user.defaultTweetText
      : '';
  }

  get user(): ModelDataInterface {
    return vxm.user.data;
  }

  close() {
    this.$emit('close');
  }

  tweet() {
    const data = {
      _id: this.video._id,
      tweetText: this.tweetText,
    };
    this.isRequestSend = true;
    vxm.model
      .tweetClip(data)
      .then(() => {
        this.close();
      })
      .catch((error) => {
        return error;
      })
      .then(() => {
        this.isRequestSend = false;
      });
  }
}
