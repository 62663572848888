


































import {Component, Mixins, Prop, Vue} from 'vue-property-decorator';
import Modal from '@/components/Modal.vue';
import {required} from 'vuelidate/lib/validators';
import DialogBlock from '@/components/DialogBlock.vue';
import InputTag from 'vue-input-tag';
import DropDown from '@/components/DropDown.vue';
import DatePicker from '@/components/DatePicker.vue';
import {vxm} from '@/store';
import {CategoryInterface} from '@/types/CategoryInterface';
import {ShortVideoInterface} from '@/types/videoInterface';
import Loader from '@/components/Loader.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import VideoForm from '@/components/VideoForm.vue';
import MomentDateMixin from '@/mixins/MomentDateMixin';
import {GendersEnum} from '@/types/enums/GendersEnum';
import {PreferencesEnum} from '@/types/enums/PreferencesEnum';

@Component({
  components: {
    Modal,
    DialogBlock,
    InputTag,
    DropDown,
    DatePicker,
    Loader,
    ProgressBar,
    VideoForm,
  },
  validations: {
    form: {
      name: {required},
    },
  },
})
export default class EditVideo extends Mixins(MomentDateMixin) {
  form: ShortVideoInterface = {
    type: 'recent',
    price: 1.99,
    name: '',
    categories: [],
    description: '',
    twitterText: '',
    tags: [],
    performer: [],
    isSchedule: false,
    schedule: '',
    status: 'active',
    sort: 0,
    isSaleVideo: false,
    file: {
      image: '',
      fileFullPath: null as any,
    },
    vidType: 'vids',
    sexualOrientation: GendersEnum.MALE,
    sexualPreference: PreferencesEnum.GAY,
  };
  categories: CategoryInterface[] = [];
  uploadInProgress = false;
  uploadInPercents = 0;
  formErrors = false;

  @Prop() readonly video!: any;

  mounted() {
    vxm.fan
      .getCategories()
      .then((res) => {
        this.categories = res.data;
      })
      .catch((error) => {
        return error;
      });
    this.syncForm();
  }
  onFormError(e: boolean) {
    this.formErrors = e;
  }
  syncForm() {
    this.form.name = this.video.name;
    this.form.description = this.video.description;
    this.form.twitterText = this.video.twitterText;
    this.form.status = this.video.status;
    if (typeof this.video.price !== 'undefined') {
      this.form.vidType = this.video.isSaleVideo ? 'vids-plus' : 'vids';
      this.form.price = this.video.price;
      this.form.categories = this.video.categories;
      this.form.twitterText = this.video.twitterText;
      this.form.tags = this.video.tags;
      this.form.isSchedule = this.video.isSchedule;
      this.form.schedule = this.video.schedule;
      this.form.isSaleVideo = this.video.isSaleVideo;
      this.form.type = this.video.type;
      this.video.performer.forEach((performer) => {
        this.form.performer.push(performer._id);
      });
    } else {
      this.form.vidType = 'clips';
      this.form.sexualOrientation = this.video.sexualOrientation;
      this.form.sexualPreference = this.video.sexualPreference;
    }
  }

  publish() {
    this.uploadInProgress = true;
    const params = {
      id: this.video._id,
      data: {},
    } as any;
    if (this.form.file.fileFullPath) {
      params.uploadProgressCallback = (progressEvent) => {
        Vue.set(this, 'uploadInPercents', Math.round((progressEvent.loaded / progressEvent.total) * 100));
      };
    }
    if (this.form.vidType === 'clips') {
      params.data = {
        description: this.form.description,
        file: this.form.file.fileFullPath,
        name: this.form.name,
        sexualOrientation: this.form.sexualOrientation,
        sexualPreference: this.form.sexualPreference,
        twitterText: this.form.twitterText,
      };
      vxm.model.updateAffiliateContentDirectUpload(params).then(
        () => {
          this.refreshModal();
        },
        () => {
          this.uploadInProgress = false;
        },
      );
    } else {
      const file = this.form.file.fileFullPath;
      params.data = JSON.parse(JSON.stringify(this.form));
      delete params.data.vidType;
      delete params.data.sexualOrientation;
      delete params.data.sexualPreference;
      params.data.file.fileFullPath = file;
      vxm.model.updateVideoDirectUpload(params).then(
        () => {
          this.refreshModal();
        },
        () => {
          this.uploadInProgress = false;
        },
      );
    }
  }

  refreshModal(reloadVids = true) {
    this.uploadInProgress = false;
    this.syncForm();
    if (
      (this.$route.name === 'manage-model-videos' ||
        this.$route.name === 'manage-model-tagged-videos' ||
        this.$route.name === 'manage-model-clips') &&
      reloadVids
    ) {
      vxm.model.setNeedUpdateVideoList(true);
    }
    if (this.form.file.fileFullPath) {
      this.$notify({
        group: 'foo',
        title: 'Your Upload has finished.',
        duration: 5000,
        type: 'success',
        text:
          'The system will now generate and convert the files for faster access. Conversions might take a while to populate.',
      });
    }
    this.close();
  }

  close() {
    this.$emit('closed');
  }

  handleFileUpload() {
    this.form.file.fileFullPath = (this.$refs.file as any).files[0];
    (this.$refs.file as any).value = '';
  }

  get coAuthorship() {
    /* sting is the type for Clips */
    if (typeof this.video.performer !== 'string') {
      return this.video.performer.filter((performer) => performer._id !== vxm.user.data._id);
    }
    return [];
  }
}
