

























import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Modal from '../Modal.vue';
import moment from 'moment';

@Component({
  components: {Modal},
})
export default class ManageContentVideoFilter extends Vue {
  showFilters = false;
  filters = {
    active: {
      name: 'Active',
      checked: false,
    },
    inactive: {
      name: 'Inactive',
      checked: false,
    },
  } as any;
  uploadedRange = {
    any: 'Any time',
    day: 'Uploaded Yesterday',
    week: 'Uploaded Last week',
    month: 'Uploaded Last month',
    year: 'Uploaded Last year',
  };
  selectedRange = 'any';

  localFilters = JSON.parse(JSON.stringify(this.filters));
  @Prop() readonly value!: any;

  get params() {
    return this.value;
  }

  set params(value) {
    this.$emit('input', value);
  }

  @Watch('value', {deep: true}) onParamsChanged() {
    this.setParams();
  }

  mounted() {
    this.setParams();
  }

  setParams() {
    if (this.$route.name === 'manage-model-videos') {
      this.filters.vids = {
        name: 'Vids',
        checked: this.value.isSaleVideo === false,
      };
      // this.filters.vidsPlus = {
      //   name: 'Vids+',
      //   checked: this.value.isSaleVideo === true,
      // };
    }
    if (this.$route.name === 'manage-model-tagged-videos') {
      this.filters.tags = {
        name: 'Tags',
        checked: false,
      };
    }
    this.filters.active = {
      name: 'Active',
      checked: this.value.status === 'active',
    };
    this.filters.inactive = {
      name: 'Inactive',
      checked: this.value.status === 'inactive',
    };
    if (!this.value.start && !this.value.end) {
      this.selectedRange = 'any';
    }
    this.localFilters = JSON.parse(JSON.stringify(this.filters));
  }

  resetFilter() {
    for (const filter in this.localFilters) {
      this.localFilters[filter].checked = false;
    }
    this.selectedRange = 'any';
    this.applyFilter();
  }

  applyFilter() {
    const params = JSON.parse(JSON.stringify(this.params));
    params.page = 1;
    if (
      (this.localFilters.active.checked && this.localFilters.inactive.checked) ||
      (!this.localFilters.active.checked && !this.localFilters.inactive.checked)
    ) {
      params.status = '';
    } else {
      params.status = this.localFilters.active.checked ? 'active' : 'inactive';
    }
    if (this.localFilters.vids && this.localFilters.vidsPlus) {
      if (
        (this.localFilters.vids.checked && this.localFilters.vidsPlus.checked) ||
        (!this.localFilters.vids.checked && !this.localFilters.vidsPlus.checked)
      ) {
        params.isSaleVideo = '';
      } else {
        params.isSaleVideo = this.localFilters.vidsPlus.checked;
      }
    }
    if (this.localFilters.tags) {
      if (this.localFilters.tags.checked) {
        params.myRole = 'performer';
        params.isSaleVideo = undefined;
      }
    }
    if (this.selectedRange !== 'any') {
      const unit = this.selectedRange as 'day' | 'week' | 'month' | 'year';
      params.start = moment()
        .subtract(1, unit)
        .startOf(unit)
        .format('YYYY-MM-DD');
      params.end = moment()
        .subtract(1, unit)
        .endOf(unit)
        .format('YYYY-MM-DD');
    } else {
      params.start = '';
      params.end = '';
    }
    this.params = params;
    this.showFilters = false;
  }
}
