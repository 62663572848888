





















































































































































































import {Component, Mixins, Watch} from 'vue-property-decorator';
import ManageContentVideoFilter from '@/components/modals/ManageContentVideoFilter.vue';
import DropDown from '@/components/DropDown.vue';
import {vxm} from '@/store';
import moment from 'moment';
import DebounceMixin from '@/mixins/DebounceMixin';
import {VideoInterface} from '@/types/videoInterface';
import Loader from '@/components/Loader.vue';
import EditVideo from '@/components/modals/EditVideo.vue';
import Paginate from 'vuejs-paginate';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';
import TweetClipModal from '@/components/modals/TweetClipModal.vue';
import {ModelDataInterface} from '@/types/userDataInterface';
import PostDetailsModal from '@/components/modals/PostDetailsModal.vue';
import {MediaEnum} from '@/types/enums/MediaEnum';

@Component({
  components: {
    TweetClipModal,
    ConfirmationModal,
    ManageContentVideoFilter,
    DropDown,
    EditVideo,
    Loader,
    Paginate,
    PostDetailsModal,
  },
})
export default class ModelVideo extends Mixins(DebounceMixin) {
  isMobile = false;
  query = '';
  totalItems = 0;
  params: any = {
    page: 1,
    take: 20,
    keyword: '',
    performerId: vxm.user.data._id,
  };
  orders = {
    newest: 'Recently added',
    mostView: 'Most Popular',
  };
  videos: VideoInterface[] = [];
  loading = false;
  debouncedSearch = this.debounce(this.search, 700);
  selectedVideo: VideoInterface | null = null;
  videoForEdit: VideoInterface | null = null;
  videoForDelete: VideoInterface | null = null;
  videoForTweet: VideoInterface | null = null;
  videoTranscodingError = '';
  timerId = 0;

  @Watch('query') onQueryChange() {
    this.debouncedSearch();
  }

  @Watch('params', {deep: true}) onParamsUpd() {
    const storage = JSON.parse(localStorage.getItem('model') as string);
    this.urlUpdate();
    if (
      storage &&
      storage[this.$route.name as string] &&
      JSON.stringify(this.$route.query) === JSON.stringify(storage[this.$route.name as string].params)
    ) {
      this.videos = storage[this.$route.name as string].items;
      this.totalItems = storage[this.$route.name as string].count;
      this.getContent(true);
    } else {
      this.getContent();
    }
  }

  @Watch('updNeeded') onUpdNeeded() {
    if (vxm.model.needUpdateVideoList) {
      this.getContent();
    }
  }

  get user(): ModelDataInterface {
    return vxm.user.data;
  }

  get updNeeded(): boolean {
    return vxm.model.needUpdateVideoList;
  }

  get pagesCount(): number {
    return Math.ceil(this.totalItems / this.params.take);
  }

  get datesRangeText(): string {
    const diff = moment(this.params.end).diff(this.params.start, 'days');
    switch (diff) {
      case 0:
        return 'Yesterday';
      case 6:
        return 'Last week';
      case 27:
      case 28:
      case 29:
      case 30:
        return 'Last month';
      case 364:
        return 'Last year';
      default:
        return `Last ${diff} days`;
    }
  }

  isMyVids(video): boolean {
    return (video.user && video.user._id === vxm.user.data._id) || video.performer === vxm.user.data._id;
  }

  showSearchBlock() {
    this.$nextTick(() => {
      (this.$refs.searchInput as HTMLInputElement).focus();
    });
  }

  search() {
    this.params.page = 1;
    this.params.keyword = this.query;
  }

  created() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
    if (this.$route.name === 'manage-model-videos' || this.$route.name === 'manage-model-tagged-videos') {
      this.params.sort = 'newest';
      // this.params.myRole = this.$route.name === 'manage-model-videos' ? 'owner' : 'performer';
      this.params.myRole = this.$route.name === 'manage-model-videos' ? 'owner' : '';
    }
    if (this.$route.query) {
      const urlParams = JSON.parse(JSON.stringify(this.$route.query));
      if (urlParams.page) {
        urlParams.page = parseInt(urlParams.page, 10);
      }
      if (urlParams.isSaleVideo) {
        urlParams.isSaleVideo = urlParams.isSaleVideo === 'true';
      }
      this.params = {...this.params, ...urlParams};
      if (this.params.keyword) {
        this.query = this.params.keyword;
      }
    }
  }

  getContent(backgroundUpdate = false) {
    this.loading = !backgroundUpdate;
    if (!backgroundUpdate) {
      clearTimeout(this.timerId);
    }
    const params = JSON.parse(JSON.stringify(this.params));
    Object.keys(params).forEach((key) => {
      if (!params[key] && typeof params[key] !== 'boolean') {
        delete params[key];
      }
    });
    let apiCall;
    if (this.$route.name === 'manage-model-videos') {
      apiCall = vxm.model.getMyVideos;
    }
    if (this.$route.name === 'manage-model-tagged-videos') {
      apiCall = vxm.model.getMyVideos;
    }
    if (this.$route.name === 'manage-model-clips') {
      apiCall = vxm.model.getMyAffiliateContent;
    }
    apiCall(params)
      .then((res) => {
        this.videos = res.data.items;
        this.totalItems = res.data.count;
        let storage = JSON.parse(localStorage.getItem('model') as string);
        if (!storage) {
          storage = {};
          storage[this.$route.name as string] = {};
        }
        storage[this.$route.name as string] = {
          params: this.$route.query,
          items: this.videos,
          count: this.totalItems,
        };
        localStorage.setItem('model', JSON.stringify(storage));
      })
      .catch((error) => {
        return error;
      })
      .then(() => {
        this.loading = false;
        this.timerId = setTimeout(() => {
          this.getContent(true);
        }, 30000);
      });
  }

  destroyed() {
    clearTimeout(this.timerId);
    window.removeEventListener('resize', this.onResize);
  }

  onResize() {
    this.isMobile = window.innerWidth <= 823;
  }

  formatDate(date): string {
    return moment(date).format('MMM DD YYYY @ HH.mm');
  }

  videoCategory(video) {
    return typeof video.price === 'number' ? (video.isSaleVideo ? 'vids-plus' : 'vids') : 'clips';
  }

  deleteVideo(confirmed?: boolean) {
    if (confirmed && this.videoForDelete) {
      if (this.videoForDelete.mediaType === MediaEnum.CLIPS) {
        vxm.model
          .deleteAffiliateContent(this.videoForDelete._id)
          .then(() => {
            this.getContent();
          })
          .catch((error) => {
            return error;
          });
      } else {
        vxm.model
          .deleteVideo(this.videoForDelete._id)
          .then(() => {
            this.getContent();
          })
          .catch((error) => {
            return error;
          });
      }
    }
    this.videoForDelete = null;
  }

  switchVideoStatus(video: VideoInterface, id) {
    const vids = JSON.parse(JSON.stringify(video));
    const params = {
      id: vids._id,
      data: new FormData(),
    };
    params.data.append('status', vids.status === 'active' ? 'inactive' : 'active');
    if (typeof vids.price === 'undefined') {
      vxm.model
        .updateAffiliateContent(params)
        .then((res) => {
          this.videos[id].status = res.data.status;
        })
        .catch((error) => {
          return error;
        });
    } else {
      vxm.model
        .updateVideo(params)
        .then((res) => {
          this.videos[id].status = res.data.status;
        })
        .catch((error) => {
          return error;
        });
    }
  }

  urlUpdate() {
    const params = JSON.parse(JSON.stringify(this.params));
    Object.keys(params).forEach((key) => {
      if (typeof params[key] !== 'boolean' && !params[key]) {
        delete params[key];
      }
      if (typeof params[key] === 'boolean') {
        params[key] = params[key] + '';
      }
    });
    delete params.take;
    delete params.performerId;
    params.page += '';
    if (JSON.stringify(params) !== JSON.stringify(this.$route.query)) {
      this.$router.replace({query: params});
    }
  }

  changeSort(key: string) {
    this.params.page = 1;
    this.params.sort = key;
  }

  getPublishDate(video: VideoInterface) {
    return video.isSchedule && video.schedule ? this.formatDate(video.schedule) : this.formatDate(video.createdAt);
  }

  showError(video: VideoInterface) {
    if (video.transcoding.statuses.main === 'failed') {
      switch (video.transcoding.errors?.main[0].code) {
        case '3001':
          this.videoTranscodingError = 'Storage error. Please try to reload your video';
          break;
        case '3002':
          this.videoTranscodingError = `Video with name "${video.name}" can’t be processed. Please rename your file and try again`;
          break;
        default:
          this.videoTranscodingError =
            'An error occurred while trying to process this video. Please check the video to be sure you can view it normally or provide a different format; be sure there are no playback errors before trying again.';
      }
    }
  }

  tweetClip(video: VideoInterface) {
    this.videoForTweet = video;
  }

  twitterConnectHandler(confirmed: boolean) {
    if (confirmed) {
      this.$router.push('/model/general');
    }

    this.videoForTweet = null;
  }
}
